import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | СпецЦентр Автообслуговування та Шиномонтажу
			</title>
			<meta name={"description"} content={"Наша автомайстерня - ваш надійний партнер у світі автомобільного обслуговування!"} />
			<meta property={"og:title"} content={"Home | СпецЦентр Автообслуговування та Шиномонтажу"} />
			<meta property={"og:description"} content={"Наша автомайстерня - ваш надійний партнер у світі автомобільного обслуговування!"} />
			<link rel={"shortcut icon"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<meta name={"msapplication-TileImage"} content={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
			<meta name={"msapplication-TileColor"} content={"https://uatrestinlive.net/images/car_repair_auto_service_icon_152274.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(43, 43, 68, 0.78) 0%,rgba(5, 7, 33, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uatrestinlive.net/images/bg1.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					СпецЦентр Автообслуговування та Шиномонтажу{"\n\n"}
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Наша автомайстерня - ваш надійний партнер у світі автомобільного обслуговування! Ми спеціалізуємося на ремонті та обслуговуванні ходової частини автомобіля, забезпечуючи найвищий стандарт безпеки та комфорту на дорозі. Але це не все, що ми можемо для вас зробити.{"\n\n"}
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="#3f24d8"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="#3f24d8"
						hover-background="rgba(63, 36, 216, 0)"
						type="link"
						text-decoration-line="initial"
						href="/Contacts"
					>
						Написати
					</Button>
				</Box>
			</Box>
		</Section>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
			background="--color-greyD1"
		>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Шиномонтаж
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Зимовий сезон наближається, і ми готові до нього на 100%! Наша команда професіоналів виконає шиномонтаж на високому рівні. Ми пропонуємо перевзування на зимову гуму з увагою до найменших деталей, щоб ви могли бути впевнені в безпеці та ефективності вашого автомобіля під час найважливіших погодних умов.{"\n\n"}
			</Text>
		</Section>
		<Components.FootedDesc />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523de57e2dd3a0022204b82"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});